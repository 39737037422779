<template>
<section class="w-full py-12">
    <div class="container mx-auto px-8">
        <h3 class="text-center text-2xl lg:text-4xl font-body py-5 font-semibold" v-html="$t('partners')"></h3>
        <div class="flex flex-col lg:flex-row py-10">
            <div class="flex-1 pb-3">
                <a href="https://www.lrvalstybe.lt/kontaktai/kalesninku-seniunija" target="_blank" rel="noopener noreferrer" class="flex flex-col justify-center items-center hover:text-orange-700">
                    <img src="@/assets/img/ks.jpg" alt="Kalesninkų Seniūnija " class="h-32" />
                    <span class="py-2">Kalesninkų Seniūnija</span>
                </a>
            </div>
            <div class="flex-1 pb-3">
                <a href="http://www.verseka.salcininkai.lm.lt/" target="_blank" rel="noopener noreferrer" class="flex flex-col justify-center items-center hover:text-orange-700">
                    <img src="@/assets/img/vmdc.jpg" alt="Versekos mokykla - Daugiafunkcinis Centras" class="h-32" />
                    <span class="py-2">Versekos mokykla - Daugiafunkcinis Centras</span>
                </a>
            </div>
            <div class="flex-1 pb-3">
                <a href="https://ibnps.eu/" target="_blank" rel="noopener noreferrer" class="flex flex-col justify-center items-center hover:text-orange-700">
                    <img src="@/assets/img/ibnps.jpg" alt="Institute of Biofeedback and Noo-psychosomatic (Lublin, PL)" class="h-32" />
                    <span class="py-2 text-center">
                        Institute of Biofeedback and Noo-psychosomatic
                        <br />(Lublin, PL)
                    </span>
                </a>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "Partners",
};
</script>
